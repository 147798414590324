import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU14Harrogate() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 14's - Harrogate</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u14-harrogate.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
            <p>
                Our focus is on ensuring our players have fun and enjoy their experience, whether training or playing matches, whilst continuing to develop theirs skills and knowledge of the game. Our aim is to improve on last years performances with focused training sessions. The majority of our squad started training at an early age via our very popular Under 7’s group.
            </p>
            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 9, DoB 01/09/2008 - 31/08/2009</dd>
                <dt>Manager(s)</dt>
                <dd>Rob Campey<br/>
                Larner Caleb<br/>
                Kev Brown</dd>
                <dt>Training</dt>
                <dd>Bedquilts. Wednesday night</dd>
                <dt>League</dt>
                <dd>Harrogate U14 League TBC</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:Rob.campey@ntlworld.com">Rob.campey@ntlworld.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-els.jpg" />
                <div className="card-body">
                    <p className="card-text">ELS - Insolvency and Business Restructuring, Financial Management Consultancy</p>
                    <div className="btn-group">
                    <a href="https://www.elsadvisory.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

